$(function () {
  $(document).scroll(function () {
	  var $nav = $(".fixed-top");
	  $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
	});
});


var links = document.links;
for (var i = 0, linksLength = links.length; i < linksLength; i++) {

    if (links[i].hostname != window.location.hostname) {
        links[i].target = '_blank';
    }

}
